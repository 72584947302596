import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import ContactForm from '../components/form';
import Helmet from 'react-helmet';
import { ImLocation2, ImPhone, ImMail3 } from 'react-icons/im';

import favicon from '../images/favicon.ico';

function Kontakt() {
    return (
        <Layout>
            <Helmet>
                <title>Kontakt - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="plåtslageri och smidesverkstad som tar uppdrag i Södertälje, Stockholm, Mälarområdet, Södermanland och Uppland." />
                <meta name="keywords" content="plåt, smide, Blästring, Rostskyddsmålning, Termisk Sprutning" />
                <meta property="og:title" content="Kontakt - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/kontakt" />
                <link rel="cannonical" href="https://danasplat.se/kontakt" />
            </Helmet>

            <MyNavbar active_page="kontakt" />
            <section>
                <div className="container mx-auto">
                    <div className="w-full md:w-2/3 lg:w-1/2 mx-auto px-4 pt-24">
                        <h1 className="text-3xl md:text-4xl font-extrabold text-center text-green-700">Vi finns närmare än du tror!</h1>
                        <p className="text-gray-600 mt-6 text-center">Kontakta oss via telefon, e-post, formulär eller varför inte komma på besök!</p>
                    </div>
                </div>



                {/*****  Contact cards  - START  ******/} 
                <section className="text-gray-600 body-font">
                    <div className="container px-4 md:px-16 py-4 w-full lg:w-2/3 mx-auto">
                        <div className="flex flex-wrap -mr-4 -ml-4">
                            <div className="p-4 w-full md:w-1/3">
                                <div className="flex flex-col items-center justify-center rounded-lg h-full p-4 bg-gray-50">      
                                    <div className="w-8 h-8 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                                        <ImLocation2/>
                                    </div>
                                    <p className="leading-relaxed text-base">Vasa Gård, 151 52 Södertälje</p>
                                </div>
                            </div>
                            <div className="p-4 w-full md:w-1/3">
                                <div className="flex flex-col items-center justify-center rounded-lg h-full p-4 bg-gray-50">      
                                    <div className="w-8 h-8 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                                        <ImPhone />
                                    </div>
                                    <p className="leading-relaxed text-base">08-550 175 11</p>
                                </div>
                            </div>
                            <div className="p-4 w-full md:w-1/3">
                                <div className="flex flex-col items-center justify-center rounded-lg h-full p-4 bg-gray-50">      
                                    <div className="w-8 h-8 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                                        <ImMail3 />
                                    </div>
                                    <p className="leading-relaxed text-base">info@danasplat.se</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*****  Contact cards  - END  ******/}   

                <div className="container px-4 md:px-16 w-full lg:w-2/3 mx-auto">
                    <div className="bg-gray-100">
                        <div className="w-full md:w-2/3 lg:w-1/2 px-4 md:px-0    mx-auto">
                            <ContactForm/>
                        </div>
                    </div>
                </div>


                <div className="container px-4 md:px-16 my-36 w-full lg:w-2/3 mx-auto">
                    <div className="mx-auto" >
                        <h2 className="text-3xl md:text-4xl font-extrabold text-center text-green-700">Danas Plåt &amp; Smide AB</h2>
                        <p className="text-center my-6 lg:mx-10 text-gray-600">Vi är ett plåtslageri &amp; smidesverkstad som har kontor och verkstad beläget på Vasa Gård i Södertälje. Vi tar till oss uppdrag i Mälarområdet, Södermanland och Uppland.</p>
                    </div>
                    <div className="w-full h-80 mx-auto ">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4087.097624263198!2d17.56370705078735!3d59.19012206651032!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe4e9cd9f9842e78!2sDanas%20Pl%C3%A5t%20%26%20Smide%20AB!5e0!3m2!1ssv!2sse!4v1625764467129!5m2!1ssv!2sse" 
                            className="w-full h-full rounded"
                            title="Danas Plat och Smide"
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    );
}
export default Kontakt;